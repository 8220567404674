<template lang="pug">
div
  .step-subtitle {{ fullVehicleName }}
  ul.model-char-list.m-t-10
    li.model-char-list-item
      span {{ $t("info.model") }}
      span {{ vehicle.model }}
    li.model-char-list-item(
      v-if="vehicle.configuration && (passengers !== null || seats !== null)"
    )
      span {{ $t("info.passengers") }}
      span {{ seats !== null ? seats : (passengers !== null) ? passengers : ''  }}
    li.model-char-list-item(v-if="vehicle.configuration && vehicle.configuration.gear !== null")
      span {{ $t("info.gearbox") }}
      span {{ vehicle.configuration.gear }}
    li.model-char-list-item
      span {{ $t("info.fuel") }}
      span {{ vehicle.engine }}
    li.model-char-list-item
      span {{ $t("info.color") }}
      span {{ vehicleColor.modelColor ? vehicleColor.modelColor.name : '' }}
    li.model-char-list-item(
      v-if="vehicle.tyre"
    )
      span {{ $t("info.tyre") }}
      div.d-flex.flex-column.align-items-end
        div {{ vehicle.tyre ? vehicle.tyre : '' }}
        div
          a.pdf-link(:href="vehicle.tyre_url" target="_blank")
            img.sm(src="~@/assets/img/pdf.svg" alt="pdf")
            | {{ vehicle.tyre_description }}
</template>

<script>
import { mapState, } from 'vuex'

export default {
  computed: {
    ...mapState('product', ['vehicle', 'vehicleColor']),
    fullVehicleName() {
      return `${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.grade} ${this.vehicle.name}`
    },
    passengers() {
      return this.vehicle.configuration.passengers;
    },
    seats() {
      return this.vehicle.configuration.seats;
    },
  }
}
</script>